import React, { useEffect, useState } from "react";
import NavBar from "../Navbar";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Alert,
} from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import EmployeesService from "../services/EmployeesService";
import { IUser } from "../interfaces/IUser";
import "../users/Users.scss";
import UserService from "../services/UserService";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Users = () => {
  let { id } = useParams();
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState(0);
  const [allUsers, setAllUsers] = useState<[IUser] | undefined>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState();
  const [addUserResult, setAddUserResult] = useState<string | undefined>();
  const [deleteResult, setDeleteResult] = useState<string | undefined>();
  const [updateUserResult, setUpdateUserResult] = useState<
    string | undefined
  >();
  let addUserResultVisibility = addUserResult ? "" : "hidden";
  let createUserVisibility = addUserResult ? "hidden" : "";
  let updateUserResultVisibility = updateUserResult ? "" : "hidden";
  let updateUserVisibility = updateUserResult ? "hidden" : "";
  let deleteResultVisibility = deleteResult ? "" : "hidden";
  let deleteButtonsVisibility = deleteResult ? "hidden" : "";

  const showDeleteUser = (index: any) => {
    setSelectedUser(index);
    handleShowConfirmation();
  };
  const handleShowConfirmation = () => setShowConfirmation(true);
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    window.location.reload();
  };

  const handleShowCreateUser = () => setShowCreateUser(true);
  const handleCloseCreateUser = () => {
    setAddUserResult(undefined);
    setShowCreateUser(false);
    window.location.reload();
  };
  const handleAddUserResult = (message: string) => {
    setAddUserResult(message);
  };

  const handleShowUpdateUser = (index: any) => {
    setSelectedUser(index);
    setShowUpdateUser(true);
  };
  const handleCloseUpdateUser = () => {
    setUpdateUserResult(undefined);
    setShowUpdateUser(false);
    window.location.reload();
  };

  const handleUpdateUserResult = (message: string) => {
    setUpdateUserResult(message);
  };

  useEffect(() => {
    const setUserData = async () => {
      try {
        var users = await new EmployeesService().getEmployees(currentPage);
        if (users === undefined) {
          history.replace("/");
        } else if (users.length) {
          setAllUsers(users);
        } else if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          history.replace("/");
        }
      }
    };
    setUserData().catch((e) => console.log(e));
  }, [currentPage, history]);

  useEffect(() => {
    const setRoleList = async () => {
      try {
        var roleList = await new UserService().getRoles();
        if (roleList !== undefined && roleList.length) {
          setRoles(roleList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    setRoleList().catch((e) => console.log(e));
  }, []);

  const goPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const getUsers = () => {
    var usersList = [];
    if (allUsers !== undefined) {
      for (let index = 0; index < allUsers?.length; index++) {
        usersList.push(
          <tr key={`tr${index}`}>
            <td key={`name${index}`}>{allUsers[index].firstName}</td>
            <td key={`last${index}`}>{allUsers[index].lastName}</td>
            <td key={`email${index}`}>{allUsers[index].email}</td>
            <td key={`roles${index}`}>
              {showRolesForUser(allUsers[index].roles)}
            </td>
            <td className="middleAlign" key={`del${index}`}>
              <IconButton
                color="secondary"
                onClick={(e: any) => showDeleteUser(index)}
              >
                <RemoveIcon></RemoveIcon>
              </IconButton>
            </td>
            <td className="middleAlign" key={`upd${index}`}>
              <IconButton onClick={(e: any) => handleShowUpdateUser(index)}>
                <EditIcon></EditIcon>
              </IconButton>
            </td>
          </tr>
        );
      }
    }

    return usersList;
  };

  const showRolesForUser = (rolesForUser: any) => {
    var userRoles = [];
    if (rolesForUser !== undefined) {
      for (let index = 0; index < rolesForUser?.length; index++) {
        userRoles.push(<p key={`role${index}`}>{rolesForUser[index]}</p>);
      }
    }
    return userRoles;
  };

  const deleteUser = async (e: any) => {
    e.preventDefault();
    if (allUsers !== undefined) {
      var userId: string = allUsers[selectedUser].id;
      var response: boolean = await new EmployeesService().deleteEmployee(
        userId
      );
      if (response === true) {
        setDeleteResult("User deleted successfully");
      } else {
        setDeleteResult("Error deleting user, please try again");
      }
    }
  };

  return (
    <div>
      <NavBar userId={id} />
      <Container fluid className="user-body">
        <Row>
          <Col xl="10" className="center-table">
            <Card>
              <Card.Body>
                <Card.Title>
                  <h3 className="users-title">Users</h3>
                  <Fab
                    color="primary"
                    className="button-submit add-button"
                    onClick={() => handleShowCreateUser()}
                  >
                    <AddIcon />
                  </Fab>
                </Card.Title>
                <Table responsive striped borderless>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Roles</th>
                    </tr>
                  </thead>
                  <tbody>{getUsers()}</tbody>
                </Table>
                <div className="page-buttons">
                  <IconButton onClick={(e: any) => goPrevious()}>
                    <ArrowBackIosIcon></ArrowBackIosIcon>
                  </IconButton>
                  <IconButton onClick={(e: any) => goNext()}>
                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </IconButton>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Dialog
          open={showCreateUser}
          onClose={handleCloseCreateUser}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${createUserVisibility}`}>Add new user</DialogTitle>
          <DialogContent>
            <CreateUser
              roles={roles}
              addUserResult={handleAddUserResult}
              visibility={`${createUserVisibility}`}
            />
          </DialogContent>
          <Alert variant="primary" className={`${addUserResultVisibility}`}>
            {addUserResult}
          </Alert>
        </Dialog>
        <Dialog
          open={showConfirmation}
          onClose={handleCloseConfirmation}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${deleteButtonsVisibility}`}>
            Are you sure you want to delete
            {allUsers !== undefined
              ? ` ${allUsers[selectedUser].firstName} ${allUsers[selectedUser].lastName}`
              : ""}
            ?
          </DialogTitle>
          <DialogContent>
            <div className={`${deleteButtonsVisibility} delete-buttons`}>
              <Button
                type="submit"
                variant="secondary"
                onClick={deleteUser}
                className={` yes-button`}
              >
                Yes
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseConfirmation}
              >
                No
              </Button>
            </div>
            <Alert variant="primary" className={`${deleteResultVisibility}`}>
              {deleteResult}
            </Alert>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showUpdateUser}
          onClose={handleCloseUpdateUser}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle className={`${updateUserVisibility}`}>
            Update user
            {allUsers !== undefined
              ? ` ${allUsers[selectedUser].firstName} ${allUsers[selectedUser].lastName}`
              : ""}
          </DialogTitle>
          <DialogContent>
            <UpdateUser
              roles={roles}
              updateUserResult={handleUpdateUserResult}
              visibility={`${updateUserVisibility}`}
              userSelected={
                allUsers !== undefined ? allUsers[selectedUser] : ""
              }
            />
          </DialogContent>
          <Alert variant="primary" className={`${updateUserResultVisibility}`}>
            {updateUserResult}
          </Alert>
        </Dialog>
      </Container>
    </div>
  );
};

export default Users;
