import { useHistory } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import React from "react";
import UserService from "./services/UserService";

const NavBar = (props: any) => {
  const history = useHistory();

  const goUsers = (e: any) => {
    e.preventDefault();
    history.push(`/admin/${props.userId}/users`);
  };

  const goHR = (e: any) => {
    e.preventDefault();
    history.push(`/admin/${props.userId}/hr`);
  };

  const goProjects = (e: any) => {
    e.preventDefault();
    history.push(`/admin/${props.userId}/projects`);
  };
  
  const logout = async (e: any) => {
    e.preventDefault();
    await new UserService().logout().finally(() => {
      history.push("/");
    });
  };

  return (
    <Navbar variant="dark" expand="lg" bg="dark" fixed="top">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link onClick={goUsers} href="">Users</Nav.Link>
          <Nav.Link onClick={goHR} href="">HR info</Nav.Link>
          <Nav.Link onClick={goProjects} href="">Projects</Nav.Link>
          <Nav.Link onClick={logout} href="">Logout</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
