import axios from "axios";
import { IUserAuthentication } from "../interfaces/IUserAuthentication";
import { IUser } from "../interfaces/IUser";

class UserService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }
  baseUrl: any;
  result: any;

  async authenticate(authenticationData: IUserAuthentication) {
    localStorage.removeItem("accessToken");

    const headers = {
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${this.baseUrl}/authentication`,
      JSON.stringify(authenticationData),
      { headers }
    );

    if (response?.status === 200) {
      localStorage.setItem("accessToken", response.data.accessToken);
    }
  }

  async getCurrentUser() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${this.baseUrl}/employees/me`, config);

    const user = response.data;

    var result: IUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      roles: user.roles,
    };

    return result;
  }

  async logout() {
    localStorage.removeItem("accessToken");
  }

  async getRoles() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${this.baseUrl}/userroles`, config);

    const roles = response.data;

    return roles;
  }

  async confirmAccount(token: string, password: string, employeeId: string) {
    const headers = {
      "Content-Type": "application/json",
    };

    let confirmation: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/authentication/confirm`,
        {
          token: token,
          password: password,
          employeeId: employeeId,
        },
        { headers }
      );

      confirmation = response;
    } catch (error) {
      console.log(error);
    }

    return confirmation;
  }
}

export default UserService;
