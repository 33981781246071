import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import HrInfoService from "../services/HrInfoService";

const CreateHrInfo = (props: any) => {
  const [image, setImage] = useState<any>();
  const [uploadImageResult, setUploadImageResult] = useState<
    string | undefined
  >();
  const [imageId, setImageId] = useState<any>();
  const [title, setTitle] = useState<any>({ sv: "", fi: "", en: "" });
  const [text, setText] = useState<any>({
    sv: "",
    fi: "",
    en: "",
  });
  const [selectedRoles, setSelectedRoles] = useState();
  let imageResultVisibility = uploadImageResult ? "" : "hidden";

  useEffect(() => {
    if (image !== undefined) {
      const uploadImage = async () => {
        try {
          var response = await new HrInfoService().uploadImage(image);
          if (response !== undefined && response.status === 200) {
            setImageId(response.data.id);
            setUploadImageResult("Image uploaded successfully");
          }
        } catch (error) {
          setUploadImageResult("Error uploading image, please try again");
        }
      };
      uploadImage().catch((e) => console.log(e));
    }
  }, [image]);

  const handleSubmitHrInfo = async (e: any) => {
    e.preventDefault();
    var response = await new HrInfoService().addHrInfo(
      selectedRoles,
      text,
      title,
      imageId
    );
    if (response.status === 200) {
      props.addHrInfoResult("News added successfully");
    } else {
      props.addHrInfoResult("Error, news not created please try again");
    }
  };

  const handleRolesChange = (e: any) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedRoles(value);
  };

  const handleTitleChange = (e: any, lang: any) => {
    const updatedTitle = { ...title };
    if (lang === "fi") {
      updatedTitle.fi = e.target.value;
      setTitle(updatedTitle);
    } else if (lang === "sv") {
      updatedTitle.sv = e.target.value;
      setTitle(updatedTitle);
    } else {
      updatedTitle.en = e.target.value;
      setTitle(updatedTitle);
    }
  };

  const handleTextChange = (e: any, lang: any) => {
    const updatedText = { ...text };
    if (lang === "fi") {
      updatedText.fi = e.target.value;
      setText(updatedText);
    } else if (lang === "sv") {
      updatedText.sv = e.target.value;
      setText(updatedText);
    } else {
      updatedText.en = e.target.value;
      setText(updatedText);
    }
  };

  const saveImage = (e: any) => {
    setImage(e.target.files[0]);
  };

  const showRoles = () => {
    var rolesList = [];
    if (props.roles !== undefined) {
      for (let index = 0; index < props.roles?.length; index++) {
        rolesList.push(
          <option key={`role${index}`}>{props.roles[index]}</option>
        );
      }
    }
    return rolesList;
  };

  return (
    <Form onSubmit={handleSubmitHrInfo} className={`${props.visibility}`}>
      <h5>Title</h5>
      <Row>
        <Col md="4">
          <Form.Group controlId="title-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in English"
              onChange={(e) => handleTitleChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in Finnish"
              onChange={(e) => handleTitleChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Title in Swedish"
              onChange={(e) => handleTitleChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Text</h5>
      <Row>
        <Col md="4">

          <Form.Group controlId="description-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Text in English"
              onChange={(e) => handleTextChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Text in Finnish"
              onChange={(e) => handleTextChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              placeholder="Text in Swedish"
              onChange={(e) => handleTextChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Target Roles</h5>
      <Form.Group controlId="roles">
        <Form.Label>Select roles</Form.Label>
        <Form.Control
          required
          as="select"
          multiple
          onChange={handleRolesChange}
        >
          {showRoles()}
        </Form.Control>
      </Form.Group>
      <Row>
        <Col md="4">
          <h5>Image</h5>
          <div id="filesubmit" className="float">
            <input
              required
              type="file"
              className="file-select"
              accept="image/*"
              onChange={saveImage}
            />
          </div>
        </Col>
      </Row>
      <Alert variant="info" className={`${imageResultVisibility}`}>
        {uploadImageResult}
      </Alert>
      <Button id="create-project-btn" type="submit">
        Create HrInfo
      </Button>
    </Form>
  );
};

export default CreateHrInfo;
