import React, { useState, useEffect } from "react";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import ProjectInfoService from "../services/ProjectInfoService";

const UpdateProjectInfo = (props: any) => {
  let baseUrl = process.env.REACT_APP_BASE_URL;
  const [image, setImage] = useState<any>();
  const [currentProjectInfo, setCurrentProjectInfo] = useState<any>();
  const [uploadImageResult, setUploadImageResult] = useState<
    string | undefined
  >();
  const [imageId, setImageId] = useState<any>();
  const [text, setText] = useState<any>({ sv: "", fi: "", en: "" });
  let imageResultVisibility = uploadImageResult ? "" : "hidden";

  useEffect(() => {
    if (image !== undefined) {
      const uploadImage = async () => {
        try {
          var response = await new ProjectInfoService().uploadImage(image);
          if (response.status === 200) {
            setImageId(response.data.id);
            setUploadImageResult("Image uploaded successfully");
          }
        } catch (error) {
          setUploadImageResult("Error uploading image, please try again");
        }
      };
      uploadImage().catch((e) => console.log(e));
    }
  }, [image]);

  useEffect(() => {
    const setDefaultText = (project: any) => {
      const updatedText = { ...text };
      updatedText.fi = project.fi.text;
      updatedText.en = project.en.text;
      updatedText.sv = project.sv.text;
      setText(updatedText);
    };

    const setProjectData = async () => {
      try {
        var projectInfo = await new ProjectInfoService().getProjectInfoAllLanguages(
          props.projectInfoSelected.id
        );
        if (projectInfo === undefined) {
          window.location.reload();
        } else {
          setCurrentProjectInfo(projectInfo);
          setDefaultText(projectInfo);
        }
      } catch (error) {
        window.location.reload();
      }
    };
    setProjectData().catch((e) => console.log(e));
  }, [props.projectInfoSelected]);

  const handleSubmitProject = async (e: any) => {
    e.preventDefault();
    var response = await new ProjectInfoService().updateProjectInfo(
      props.projectInfoSelected.id,
      props.projectId,
      text,
      imageId
    );
    if (response !== undefined && response.status === 200) {
      props.updateProjectInfoResult("Project info updated successfully");
    } else {
      props.updateProjectInfoResult(
        "Error updating project info, please try again"
      );
    }
  };

  const handleTextChange = (e: any, lang: any) => {
    const updatedText = { ...text };
    if (lang === "fi") {
      updatedText.fi = e.target.value;
      setText(updatedText);
    } else if (lang === "sv") {
      updatedText.sv = e.target.value;
      setText(updatedText);
    } else {
      updatedText.en = e.target.value;
      setText(updatedText);
    }
  };

  const saveImage = (e: any) => {
    setImage(e.target.files[0]);
  };

  return (
    <Form onSubmit={handleSubmitProject} className={`${props.visibility}`}>
      <h5>Text</h5>
      <Form.Group controlId="description-en">
        <Form.Label>English</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          defaultValue={currentProjectInfo?.en.text}
          onChange={(e) => handleTextChange(e, "en")}
        />
      </Form.Group>
      <Form.Group controlId="description-fi">
        <Form.Label>Finnish</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          defaultValue={currentProjectInfo?.fi.text}
          onChange={(e) => handleTextChange(e, "fi")}
        />
      </Form.Group>
      <Form.Group controlId="description-sv">
        <Form.Label>Swedish</Form.Label>
        <Form.Control
          required
          type="text"
          as="textarea"
          defaultValue={currentProjectInfo?.sv.text}
          onChange={(e) => handleTextChange(e, "sv")}
        />
      </Form.Group>

      <h5>Image</h5>
      <Row>
        <Col md="4">
          <div className="float-inline">
            <input
              type="file"
              className="file-select"
              accept="image/*"
              onChange={saveImage}
            />
          </div>
        </Col>
        <Col md="4">
          <div>
            <p>Original picture</p>
            <img
              alt=""
              src={baseUrl + "/" + currentProjectInfo?.en.imageUrl}
              className="thumbnail float-inline"
            />
          </div>
        </Col>
      </Row>
      <Alert variant="info" className={`${imageResultVisibility} clear-alert`}>
        {uploadImageResult}
      </Alert>
      <Button id="create-project-btn" type="submit">
        Update project news
      </Button>
    </Form>
  );
};

export default UpdateProjectInfo;
