import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Container, Alert } from "react-bootstrap";
import Button from "@material-ui/core/Button/Button";
import UserService from "../services/UserService";
import "../users/Password.scss";

const Password = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [token, setToken] = useState();
  const [employee, setEmployee] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  let errorVisibility = error ? "" : "hidden";

  useEffect(() => {
    const queryString = require("query-string");
    const parsed = queryString.parse(location.search);
    if (location.search !== undefined && location.search !== "") {
      setToken(parsed.token);
      setEmployee(parsed.employee);
    }
  }, [location.search]);

  const isPasswordInvalid = (password: string): Boolean => {
    var regexp = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
    );
    var result = regexp.test(password);
    return !result;
  };

  const handleSubmitPassword = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (isPasswordInvalid(form.password.value)) {
      setError(true);
      setErrorMessage(
        "Password is invalid. Please check that length is at least 6 characters, combining uppercase and lowercase letters, at least one digit and one special character. "
      );
    } else if (form.password.value !== form.passwordRepeat.value) {
      setError(true);
      setErrorMessage(
        "Passwords don't match "
      );
    } else {
      setError(false);
      confirmPassword(form.password.value);
    }
  };

  const confirmPassword = async (pass: string) => {
    const response = await new UserService().confirmAccount(
      token,
      pass,
      employee
    );
    if (response?.status === 200) {
      setError(false);
      history.replace(`/confirmation/${response?.data.roles.includes("Administrator")}`);
    } else {
      setError(true);
      setErrorMessage("Error creating password, please try again");
    }
  };

  return (
    <Container fluid id="password-setup">
      <Form onSubmit={handleSubmitPassword}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="password" placeholder="Password" />
        </Form.Group>
        <Form.Group controlId="passwordRepeat">
          <Form.Label>Repeat password</Form.Label>
          <Form.Control required type="password" placeholder="Password" />
          <Alert
            variant={"danger"}
            className={`error-alert ${errorVisibility}`}
          >
            <Alert.Heading>Error</Alert.Heading>
            {errorMessage}
          </Alert>
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default Password;
