import axios from "axios";
import { IProjectInfo } from "../interfaces/IProjectInfo";

class ProjectInfoService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }
  baseUrl: any;
  result: any;

  async getProjectInfos(
    projectId: string,
    currentPage: number,
    language: string
  ): Promise<[IProjectInfo]> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let projects: any;

    const response = await axios.get(
      `${this.baseUrl}/projectinfo?ProjectId=${projectId}&Page=${currentPage}&PageSize=10`,
      config
    );
    projects = response.data;

    return projects;
  }

  async uploadImage(image: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const formData = new FormData();
    formData.append("image", image);

    let result: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/projectinfo/image`,
        formData,
        config
      );

      result = response;
    } catch (error) {
      console.log(error);
    }

    return result;
  }

  async addProjectInfo(
    projectId: any,
    text: any,
    projectImageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let createResult: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/projectinfo`,
        {
          projectId: projectId,
          text: text,
          imageId: projectImageId
        },
        config
      );
      createResult = response;
    } catch (error) {
      console.log(error);
    }

    return createResult;
  }

  async deleteProjectInfo(id: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let deleteResult: any;

    try {
      const response = await axios.delete(
        `${this.baseUrl}/projectinfo/${id}`,
        config
      );
      deleteResult = response.status;
    } catch (error) {
      console.log(error);
    }

    var result = false;
    if (deleteResult === 200) {
      result = true;
    }
    return result;
  }

  async getProjectInfoAllLanguages(id: string): Promise<any> {
    let projectInfoAllLanguages: any;

    try {
      const projectSv = await this.getProjectInfo(id, "sv");
      const projectFi = await this.getProjectInfo(id, "fi");
      const projectEn = await this.getProjectInfo(id, "en");
      projectInfoAllLanguages = {
        sv: projectSv,
        fi: projectFi,
        en: projectEn,
      };
    } catch (error) {
      console.log(error);
    }

    return projectInfoAllLanguages;
  }

  async getProjectInfo(id: string, language: string): Promise<IProjectInfo> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let project: any;

    const response = await axios.get(`${this.baseUrl}/projectinfo/${id}`, config);
    project = response.data;

    return project;
  }

  async updateProjectInfo(
    id: any,
    projectId: any,
    text: any,
    imageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let updateResult: any;

    try {
      let response: any;
      if (imageId !== undefined) {
        response = await axios.put(
          `${this.baseUrl}/projectinfo/${id}`,
          {
            id: id,
            projectId: projectId,
            text: text,
            imageId: imageId,
          },
          config
        );
      } else {
        response = await axios.put(
          `${this.baseUrl}/projectinfo/${id}`,
          {
            id: id,
            projectId: projectId,
            text: text
          },
          config
        );
      }

      updateResult = response;
    } catch (error) {
      console.log(error);
    }

    return updateResult;
  }

};

export default ProjectInfoService;