import axios from "axios";
import { IHrInfo } from "../interfaces/IHrInfo";

class HrInfoService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }
  baseUrl: any;
  result: any;

  async getAllHrInfo(
    currentPage: number,
    language: string
  ): Promise<[IHrInfo]> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let hrInfo: any;

    const response = await axios.get(
      `${this.baseUrl}/hrinfo/all?Page=${currentPage}&PageSize=10`,
      config
    );
    hrInfo = response.data;

    return hrInfo;
  }

  async uploadImage(image: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const formData = new FormData();
    formData.append("image", image);

    let result: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/hrinfo/image`,
        formData,
        config
      );

      result = response;
    } catch (error) {
      console.log(error);
    }

    return result;
  }

  async addHrInfo(
    targetRoles: any,
    text: any,
    title: any,
    imageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let createResult: any;

    try {
      const response = await axios.post(
        `${this.baseUrl}/hrinfo`,
        {
          title: title,
          text: text,
          imageId: imageId,
          targetRoles: targetRoles,
        },
        config
      );
      createResult = response;
    } catch (error) {
      console.log(error);
    }

    return createResult;
  }

  async deleteHrInfo(id: any) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let deleteResult: any;

    try {
      const response = await axios.delete(
        `${this.baseUrl}/hrinfo?id=${id}`,
        config
      );
      deleteResult = response.status;
    } catch (error) {
      console.log(error);
    }

    var result = false;
    if (deleteResult === 200) {
      result = true;
    }
    return result;
  }

  async getHrInfoAllLanguages(id: string): Promise<any> {
    let hrInfoAllLanguages: any;

    try {
      const hrSv = await this.getHrInfo(id, "sv");
      const hrFi = await this.getHrInfo(id, "fi");
      const hrEn = await this.getHrInfo(id, "en");
      hrInfoAllLanguages = {
        sv: hrSv,
        fi: hrFi,
        en: hrEn,
      };
    } catch (error) {
      console.log(error);
    }

    return hrInfoAllLanguages;
  }

  async getHrInfo(id: string, language: string): Promise<IHrInfo> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        "Content-Language": language,
      },
    };

    let hr: any;

    const response = await axios.get(`${this.baseUrl}/hrinfo/${id}`, config);
    hr = response.data;

    return hr;
  }

  async updateHrInfo(
    id: any,
    text: any,
    title: any,
    targetRoles: any,
    imageId: any
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    let updateResult: any;

    try {
      let response: any;
      if (imageId !== undefined) {
        response = await axios.put(
          `${this.baseUrl}/projectinfo/${id}`,
          {
            id: id,
            title: title,
            text: text,
            targetRoles: targetRoles,
            imageId: imageId,
          },
          config
        );
      } else {
        response = await axios.put(
          `${this.baseUrl}/hrinfo/${id}`,
          {
            id: id,
            title: title,
            text: text,
            targetRoles: targetRoles,
          },
          config
        );
      }

      updateResult = response;
    } catch (error) {
      console.log(error);
    }

    return updateResult;
  }

}

export default HrInfoService;
