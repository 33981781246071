import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../login/Login";
import Admin from "../Admin";
import Users from "../users/Users";
import Projects from "../projects/Projects";
import Password from "../users/Password";
import Confirmation from "../users/Confirmation";
import ProjectInfo from "../projectInfo/ProjectInfoList";
import HrInfoList from "../hrinfo/HrInfoList";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={"/admin/:id/users"}>
          <Users />
        </Route>
        <Route path={"/admin/:id/projects"}>
          <Projects />
        </Route>
        <Route path={"/admin/:id/projectInfo/:projectId"}>
          <ProjectInfo />
        </Route>
        <Route path={"/admin/:id/hr"}>
          <HrInfoList />
        </Route>
        <Route path={"/confirmation/:admin"}>
          <Confirmation />
        </Route>
        <Route path={"/password"}>
          <Password />
        </Route>
        <Route exact path={"/"}>
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
