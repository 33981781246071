import React, { useState, useEffect } from "react";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import "./Projects.scss";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ProjectsService from "../services/ProjectsService";

const UpdateProject = (props: any) => {
  let baseUrl = process.env.REACT_APP_BASE_URL;
  let moment = require("moment");
  const [image, setImage] = useState<any>();
  const [currentProject, setCurrentProject] = useState<any>();
  const [uploadImageResult, setUploadImageResult] = useState<
    string | undefined
  >();
  const [imageId, setImageId] = useState<any>();
  const [title, setTitle] = useState<any>({ sv: "", fi: "", en: "" });
  const [description, setDescription] = useState<any>({
    sv: "",
    fi: "",
    en: "",
  });
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  let imageResultVisibility = uploadImageResult ? "" : "hidden";

  useEffect(() => {
    if (image !== undefined) {
      const uploadImage = async () => {
        try {
          var response = await new ProjectsService().uploadImage(image);
          if (response !== undefined && response.status === 200) {
            setImageId(response.data.id);
            setUploadImageResult("Image uploaded successfully");
          }
        } catch (error) {
          setUploadImageResult("Error uploading image, please try again");
        }
      };
      uploadImage().catch((e) => console.log(e));
    }
  }, [image]);

  useEffect(() => {
    const setDefaultTitleAndDescription = (project: any) => {
      const updatedTitle = { ...title };
      updatedTitle.fi = project.fi.title;
      updatedTitle.en = project.en.title;
      updatedTitle.sv = project.sv.title;
      setTitle(updatedTitle);
      const updatedDescription = { ...description };
      updatedDescription.fi = project.fi.description;
      updatedDescription.en = project.en.description;
      updatedDescription.sv = project.sv.description;
      setDescription(updatedDescription);
    };
    const setProjectData = async () => {
      try {
        var project = await new ProjectsService().getProjectAllLanguages(
          props.projectSelected.id
        );
        if (project === undefined) {
          window.location.reload();
        } else {
          setCurrentProject(project);
          setDefaultTitleAndDescription(project);
          setStartDate(moment(project.en.startDate));
          setEndDate(moment(project.en.endDate));
        }
      } catch (error) {
        window.location.reload();
      }
    };
    setProjectData().catch((e) => console.log(e));
  }, [props.projectSelected]);

  const handleSubmitProject = async (e: any) => {
    e.preventDefault();
    var response = await new ProjectsService().updateProject(
      props.projectSelected.id,
      title,
      description,
      startDate.add(1, 'days').format(),
      endDate.add(1, 'days').format(),
      imageId
    );
    if (response.status === 200) {
      props.updateUserResult("Project updated successfully");
    } else {
      props.updateUserResult("Error updating project, please try again");
    }
  };

  const handleTitleChange = (e: any, lang: any) => {
    const updatedTitle = { ...title };
    if (lang === "fi") {
      updatedTitle.fi = e.target.value;
      setTitle(updatedTitle);
    } else if (lang === "sv") {
      updatedTitle.sv = e.target.value;
      setTitle(updatedTitle);
    } else {
      updatedTitle.en = e.target.value;
      setTitle(updatedTitle);
    }
  };

  const handleDescriptionChange = (e: any, lang: any) => {
    const updatedDescription = { ...description };
    if (lang === "fi") {
      updatedDescription.fi = e.target.value;
      setDescription(updatedDescription);
    } else if (lang === "sv") {
      updatedDescription.sv = e.target.value;
      setDescription(updatedDescription);
    } else {
      updatedDescription.en = e.target.value;
      setDescription(updatedDescription);
    }
  };

  const saveImage = (e: any) => {
    setImage(e.target.files[0]);
  };

  const handleStartDateChange = (e: any) => {
    setStartDate(e);
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e);
  };

  return (
    <Form onSubmit={handleSubmitProject} className={`${props.visibility}`}>
      <h5>Title</h5>
      <Row>
        <Col md="4">
          <Form.Group controlId="title-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={currentProject?.en.title}
              onChange={(e) => handleTitleChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={currentProject?.fi.title}
              onChange={(e) => handleTitleChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="title-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={currentProject?.sv.title}
              onChange={(e) => handleTitleChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Description</h5>
      <Row>
        <Col md="4">
          <Form.Group controlId="description-en">
            <Form.Label>English</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              defaultValue={currentProject?.en.description}
              onChange={(e) => handleDescriptionChange(e, "en")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-fi">
            <Form.Label>Finnish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              defaultValue={currentProject?.fi.description}
              onChange={(e) => handleDescriptionChange(e, "fi")}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="description-sv">
            <Form.Label>Swedish</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              defaultValue={currentProject?.sv.description}
              onChange={(e) => handleDescriptionChange(e, "sv")}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5>Dates</h5>
      <Row>
        <Col md="4">
          <div className="date-start">
            <KeyboardDatePicker
              required
              id="start-date"
              label="Start date"
              value={startDate}
              format="DD/MM/yyyy"
              onChange={(e) => handleStartDateChange(e)}
            />
          </div>
        </Col>
        <Col md="4">
          <div className="date-end">
            <KeyboardDatePicker
              required
              id="end-date"
              label="End date"
              value={endDate}
              format="DD/MM/yyyy"
              onChange={(e) => handleEndDateChange(e)}
            />
          </div>
        </Col>
      </Row>
      <h5>Image</h5>
      <Row>
        <Col md="4">
          <div className="float-inline">
            <input
              type="file"
              className="file-select"
              accept="image/*"
              onChange={saveImage}
            />
          </div>
        </Col>
        <Col md="4">
          <div>
            <p>Original picture</p>
            <img
              alt=""
              src={baseUrl + "/" + currentProject?.en.imageUrl}
              className="thumbnail float-inline"
            />
          </div>
        </Col>
      </Row>
      <Alert variant="info" className={`${imageResultVisibility} clear-alert`}>
        {uploadImageResult}
      </Alert>
      <Button id="create-project-btn" type="submit">
        Update project
      </Button>
    </Form>
  );
};

export default UpdateProject;
